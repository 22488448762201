import React from "react";
import logo from "../assets/img/mijnlogo.png";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src={logo} />
        <p className="mb-0 footer-section__text mt-1">
          ©2022 - Alle rechten voorbehouden.
        </p>
      </div>
    </div>
  );
};

export default Footer;
