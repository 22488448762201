const initialState = {
  loading: false,
  moisture_response: null,
  error_response: null,
};

export default function moistureReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "SET_MOISTURE_LOADING":
      return {
        ...state,
        loading: payload,
      };
    case "SET_SECRET_ID":
      return {
        ...state,
        moisture_response: payload,
      };
    case "ERROR_RESPONSE":
      return {
        ...state,
        error_response: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
