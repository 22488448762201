import { RepositoryFactory } from "../../repository/RepositoryFactory";
let moisture = RepositoryFactory.get("moisture");

export const setMoistureLoading = (val) => async (dispatch) => {
  dispatch({ type: "SET_MOISTURE_LOADING", payload: val });
};

export const addLead = (payload, history) => async (dispatch) => {
  dispatch(setMoistureLoading(true));
  if (payload.bootCheck) {
    dispatch(setMoistureLoading(false));
    history.push("/bedankt");
  } else {
    try {
      let { data } = await moisture.addLead(payload);
      if (data) {
        dispatch({ type: "SET_SECRET_ID", payload: data });
        history.push("/bedankt");
      }
      dispatch(setMoistureLoading(false));
    } catch (error) {
      let { data } = error.response;
      if (data.error == "Conflict - duplicate") {
        console.log(data.error);
        dispatch(setMoistureLoading(false));
        history.push("/bedankt");
      } else {
        dispatch(setMoistureLoading(false));
        let errorName = error.response.data.error
          .match(/'([^']+)'/)[0]
          .slice(1, -1);
        dispatch({
          type: "ERROR_RESPONSE",
          payload: { [errorName]: true, name: errorName },
        });
      }
    }
  }
};

export const setErrorResponse = () => async (dispatch) => {
  dispatch({ type: "ERROR_RESPONSE", payload: null });
};
