import AuthView from "../views/auth/AuthView";
import Home from "../views/Home";
import ThankYou from "../views/ThankYou";

let routes = [
  // {
  //   path: "/auth",
  //   component: AuthView,
  //   layout: "auth",
  // },
  // {
  //   path: "/vochtbestrijding",
  //   component: Home,
  //   layout: "main",
  // },
  // { path: "/bedankt", component: ThankYou, layout: "main" },
];
export default routes;
