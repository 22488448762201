import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import logo from "../assets/img/mijnlogo.png";

const Header = () => {
  return (
    <div>
      <Navbar>
        <NavbarBrand href="/vochtbestrijding" className="mx-auto">
          <img src={logo} />
        </NavbarBrand>
      </Navbar>
    </div>
  );
};

export default Header;
