// Root Reducer

import { combineReducers } from "redux";
import moistureReducer from "./moistureReducer";

export let rootReducer = combineReducers({
  moisture: moistureReducer,
});

export default rootReducer;
