import axios from "axios";
const baseURL = "https://leadgen.republish.nl/api/sponsors";

export default axios.create({
  baseURL,
  headers: {
    Authorization:
      "Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=",
  },
});
